<template>
    <div class="nk-app-root" style="margin-top: -65px;">
        <!-- wrap @s -->
        <div class="nk-wrap ">
            <!-- main header @s -->
            <div class="nk-header nk-header-fluid is-dark">
                <div class="container-xl wide-xl">
                    <div class="nk-header-wrap">
                        <div class="nk-menu-trigger mr-sm-2 d-lg-none hideOnPrint">
                            <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em class="icon ni ni-menu"></em></a>
                        </div>
                        <div class="nk-header-brand">
                            <router-link :to="{ name: 'crm'}" class="logo-link">
                     <div style="font-size:24px; font-weight:600; margin-top:-5px;">CRM</div>
                     <!-- <img class="logo-light logo-img" src="@/assets/images/logo-dark-small.png"  alt="logo">
                    <img class="logo-dark logo-img" src="@/assets/images/logo-dark-small.png" alt="logo-dark"> -->
                            </router-link>
                        </div><!-- .nk-header-brand -->
                        <div class="nk-header-menu" data-content="headerNav" style="width: 60%;">
                            <div class="nk-header-mobile">
                                <div class="nk-header-brand">
                                    <router-link :to="{ name: 'crm' }" class="logo-link">
                                        <img class="logo-light logo-img" src="@/assets/images/logos/logo-small-light.svg"  alt="logo">
                                        <img class="logo-dark logo-img" src="@/assets/images/logos/logo-small-light.svg" alt="logo-dark">
                                    </router-link>
                                </div>
                                <div class="nk-menu-trigger mr-n2 hideOnPrint">
                                    <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="headerNav"><em class="icon ni ni-arrow-left"></em></a>
                                </div>
                            </div>
                            <topmenu></topmenu>
                        </div><!-- .nk-header-menu -->
                        <headertools></headertools>
                    </div><!-- .nk-header-wrap -->
                </div><!-- .container-fliud -->
            </div>
            <!-- main header @e -->
            <!-- content @s -->
            <div class="nk-content nk-content-fluid">
                <div class="container-xxxl">
                    <div class="nk-content-inner">
                        <div class="nk-content-body">
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
            <!-- content @e -->
        </div>
        <!-- wrap @e -->
    </div>
        
     
</template>

<script>
import headertools from '@/components/layout/headertools'
import topmenu from '@/components/layout/topmenu'
export default {  
    components: {
        headertools,
        topmenu,
    },
    data () {
        return {
            sidebarMenuOpen: false
        }
    },
    methods: {
        toggleSidebarMenu () {
            if (this.sidebarMenuOpen === false) {
                this.sidebarMenuOpen = true
            } else {
                this.sidebarMenuOpen = false
            }
        },
    },
}
</script>

<style>

</style>