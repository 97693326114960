<template>
    <div class="nk-header-tools">
        <ul class="nk-quick-nav">
            <messages/>
            <notifications/>
            <li class="dropdown list-apps-dropdown">
                <a v-on:click="toggleAppMenu()"  class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                    <div class="icon-status icon-status-na"><em class="icon ni ni-menu-circled"></em></div>
                </a>
                <div @mouseover="showAppMenu = true" @mouseleave="showAppMenu = false" class="dropdown-menu dropdown-menu-lg dropdown-menu-right" v-bind:class="{'show' : showAppMenu}">
                    <div class="dropdown-body">
                        <ul class="list-apps">
                            <li>
                                <router-link :to="{name: $store.state.userSettings.dashboard}">
                                    <span class="list-apps-media"><em class="icon ni ni-dashlite bg-primary text-white"></em></span>
                                    <span class="list-apps-title">Dashboard</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'mymessages' }">
                                    <span class="list-apps-media"><em class="icon ni ni-chat-circle bg-info-dim"></em></span>
                                    <span class="list-apps-title">Messages</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(1)">
                                <router-link :to="{ name: 'tickets' }">
                                    <span class="list-apps-media"><em class="icon ni ni-ticket bg-danger-dim"></em></span>
                                    <span class="list-apps-title">Tickets</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(2)">
                                <router-link :to="{ name: 'docs' }">
                                    <span class="list-apps-media"><em class="icon ni ni-folder bg-azure-dim"></em></span>
                                    <span class="list-apps-title">Documents</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(3)">
                                <router-link :to="{ name: 'people' }">
                                    <span class="list-apps-media"><em class="icon ni ni-users bg-blue-dim"></em></span>
                                    <span class="list-apps-title">People</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'inet' }">
                                    <span class="list-apps-media"><em class="icon ni ni-share-alt bg-pink-dim"></em></span>
                                    <span class="list-apps-title">iNet</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(1)">
                                <router-link :to="{ name: 'desk' }">
                                    <span class="list-apps-media"><em class="icon ni ni-help-alt bg-purple-dim"></em></span>
                                    <span class="list-apps-title">Desk</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'dashboards' }">
                                    <span class="list-apps-media"><em class="icon ni ni-menu-circled bg-success-dim"></em></span>
                                    <span class="list-apps-title">Dashboards</span>
                                </router-link>
                            </li>
                            <li v-if="$hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3')">
                                <router-link :to="{ name: 'crm' }">
                                    <span class="list-apps-media"><em class="icon ni ni-package-fill bg-light-dim"></em></span>
                                    <span class="list-apps-title">CRM</span>
                                </router-link>
                            </li>
                            <li v-if="$hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3')">
                                <router-link :to="{ name: 'reports' }">
                                    <span class="list-apps-media"><em class="icon ni ni-growth bg-success-dim"></em></span>
                                    <span class="list-apps-title">Reports</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li   class="dropdown user-dropdown">
                <a v-on:click="toggleMenu"   class="dropdown-toggle mr-n1" data-toggle="dropdown">
                    <div class="user-toggle">
                        <div class=" sm">
                            <avatar :src="$store.state.userSettings.avatarURI" :size="32" :rounded="true" v-if="$store.state.userSettings.firstName" :username="$store.state.userSettings.firstName + ' ' + $store.state.userSettings.lastName" />
                        </div>
                    </div>
                </a>
                <div @mouseover="showMenu = true" @mouseleave="showMenu = false" class="dropdown-menu dropdown-menu-md dropdown-menu-right" v-bind:class="{'show' : showMenu}">
                    <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                        <div class="user-card">
                            <div class="" style="padding: 0px 10px;">
                                    <avatar :src="$store.state.userSettings.avatarURI" :size="32" :rounded="true" v-if="$store.state.userSettings.firstName" :username="$store.state.userSettings.firstName + ' ' + $store.state.userSettings.lastName" />
                            </div>
                            <div class="user-info">
                                <span class="lead-text">{{$store.state.userSettings.firstName + ' ' + $store.state.userSettings.lastName}}</span>
                                <span class="sub-text">{{$store.state.userSettings.email}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-inner">
                        <ul class="link-list">
                            <li><router-link :to="{name: 'myprofile'}" ><em class="icon ni ni-user-alt"></em><span>Profile</span></router-link></li>
                            <li><router-link :to="{name: 'mysettings'}" ><em class="icon ni ni-setting-alt"></em><span>Account Setting</span></router-link></li>
                            <li><router-link :to="{name: 'myactivity'}" ><em class="icon ni ni-activity-alt"></em><span>Login Activity</span></router-link></li>
                            <li><a class="dark-switch" v-on:click="toggleDarkMode()" ><em class="icon ni ni-moon"></em><span>Dark Mode</span></a></li>
                        </ul>
                    </div>
                    <div class="dropdown-inner">
                        <ul class="link-list">
                            <li><a v-on:click="logOut()"><em class="icon ni ni-signout"></em><span>Sign out</span></a></li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div><!--x-->
</template>

<script>
import notifications from '@/components/notifications/index.vue'
import messages from '@/components/messages/index.vue'

export default {
    components: { notifications, messages },
    data () {
        return {
            showNotifications: false,
            showChats: false,
            showMenu: false,
            showAppMenu: false,
            darkMode: false
        }
    },
methods:{
    logOut () {
      localStorage.removeItem('trimToken')
      this.$router.push('/login')
    },
    toggleChats () {
        this.showNotifications = false
        this.showMenu = false
        this.showAppMenu = false
        if (this.showChats) {
            this.showChats  = false
        } else {
            this.showChats  = true
        }
    },
    toggleAppMenu() {
        this.showChats = false
        this.showNotifications = false
    if (this.showAppMenu) {
            this.showAppMenu  = false
        } else {
            this.showAppMenu  = true
        }
    },
    toggleNotifications () {
        this.showChats = false
        this.showMenu = false
        this.showAppMenu = false
        if (this.showNotifications) {
            this.showNotifications  = false
        } else {
            this.showNotifications  = true
        }
    },
    toggleMenu () {
        this.showChats = false
        this.showNotifications = false
        this.showAppMenu = false
        if (this.showMenu) {
            this.showMenu  = false
        } else {
            this.showMenu  = true
        }
    }  ,
    toggleDarkMode() {
    const el = document.body;

    if (this.darkMode === false) {
        this.darkMode = true
        el.classList.add('dark-mode');
    } else {
        this.darkMode = false
        el.classList.remove('dark-mode');
    }
  },

},
}
</script>

<style>

</style>