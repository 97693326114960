/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'
import router from '../../router/index';

const API_URL = (window.location.href.indexOf('localhost') >= 0) ? 'http://localhost:7672' :  (window.location.href.indexOf('test.') >= 0) ? 'https://testapi.softsuite.app' : 'https://api.softsuite.app'


console.log('Working from :' + API_URL)
const axiosInstance  =  axios.create({
  baseURL: API_URL,
  headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('trimToken')}
}
)

axiosInstance.interceptors.response.use((response) => {
  return response
}, (error) => { 
  if (error.response != null)
  {
  if (error.response.status == 403) {
    localStorage.removeItem('trimToken')
    router.push({ path: '/unauthorized' }) 
  }
}
  return Promise.reject(error)
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('trimToken')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config;
  },
  (error) => Promise.reject(error),
)

export default axiosInstance
