<template>
            <li class="dropdown chats-dropdown hide-mb-xs">
                <a  v-on:click="toggleChats()" class="dropdown-toggle nk-quick-nav-icon " data-toggle="dropdown">
                    <div :class="{'icon-status-info' : hasNewMessage, 'icon-status-na' : !hasNewMessage}" class="icon-status "><em class="icon ni ni-comments"></em></div>
                </a>
                <div @mouseover="showChats = true" @mouseleave="showChats = false" class="dropdown-menu dropdown-menu-xl dropdown-menu-right" v-bind:class="{'show' : showChats}">
                    <div>
        <div class="dropdown-body">
            <ul class="chat-list" >
                <template v-if="messages && messages.length">
                <li  class="chat-item" v-for="message in messages" :key="message.id">
                    <router-link class="chat-link" :to="{name: 'mymessages'}">
                        <div v-if="message.createdByUser">
                            <avatar style="margin:15px;" :src="message.createdByUserAvatarUri" :size="35" :rounded="true" :username="message.createdByUser" />
                        </div>
                        <div class="chat-info">
                            <div class="chat-from">
                                <div class="name">{{message.createdByUser}}</div>
                                <span class="time">{{$moment(message.createdDate).fromNow()}}</span>
                            </div>
                            <div class="chat-context">
                                <div class="text">{{message.content}}</div>
                            </div>
                        </div>
                    </router-link>
                </li>
                </template>
                <!-- <li class="chat-item">
                    <a class="chat-link" href="chat">
                        <div class="chat-media user-avatar">
                            <img src="@/assets/images/avatar/a-sm.jpg" alt="">
                            <span class="status dot dot-lg dot-success"></span>
                        </div>
                        <div class="chat-info">
                            <div class="chat-from">
                                <div class="name">Guy</div>
                                <span class="time">3 Apr</span>
                            </div>
                            <div class="chat-context">
                                <div class="text">Hi Frank! How is you doing?</div>
                            </div>
                        </div>
                    </a>
                </li> 
                <li class="chat-item">
                    <a class="chat-link" href="chat">
                        <div class="chat-media user-avatar bg-purple">
                            <span>TW</span>
                        </div>
                        <div class="chat-info">
                            <div class="chat-from">
                                <div class="name">Ram M</div>
                                <span class="time">27 Mar</span>
                            </div>
                            <div class="chat-context">
                                <div class="text">You: I just bought a new computer but i am having some problem</div>
                                <div class="status sent">
                                    <em class="icon ni ni-check-circle"></em>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>  -->
            </ul> 
        </div>
        <div class="dropdown-foot center">
            <router-link :to="{name: 'mymessages'}">View All</router-link>
        </div>
    </div>
                </div>
            </li>
</template>

<script>
export default {
    data () {
        return {
            showChats: false,
            maxMessageId: 0,
            messages: null,
            hasNewMessage: false,
        }
    },
    created () {
        this.getMessages()
       
    },
    methods: {
        closeList() {
              this.showChats  = false
        },
        toggleChats () {
        this.hasNewMessage = false
        if (this.showChats) {
            this.showChats  = false
        } else {
            this.showChats  = true
            this.$emit('openMessages')
        }
        },
        getMessages () {
            this.$http.get('/messaging/Get_RecentMessages/')
            .then((response) => {
                if (response.data) {
                    this.messages = response.data
                    if (this.messages.length) {
                        this.maxMessageId = this.messages[0].id
                    }
                    this.messages.forEach(msg => {
                        if (!msg.readDate) {
                            this.hasNewMessage = true 
                        }
                    })
                }
                this.startFetcher()
                
            })
        },
        startFetcher () {
            setInterval(() => {
                this.getNewMessages()
            }, 15000) // every 15 seconds
        },
        getNewMessages () {
            this.$http.get('/messaging/Get_NewMessages/' + this.maxMessageId)
            .then((response) => {
                if (response.data && response.data.length){
                    response.data.forEach(msg => {
                        this.messages.unshift(msg)
                    });
                     this.hasNewMessage = true
                     this.maxMessageId = this.messages[0].id
                }
            })
        },
    },
}
</script>

<style>

</style>