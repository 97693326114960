<template>
    <div>
        <div class="nk-app-root">
        <div class="nk-apps-sidebar is-dark">
            <div class="nk-apps-brand">
                <router-link class="logo-link" :to="{name: $store.state.userSettings.dashboard}">
                    <img class="logo-light logo-img" src="@/assets/images/logos/logo-small-light.png"  alt="logo">
                    <img class="logo-dark logo-img" src="@/assets/images/logos/logo-small-light.png" alt="logo-dark">
                </router-link>
            </div>
            <sidebar></sidebar>
        </div>
        <div class="nk-main ">
            <div class="nk-wrap ">
                <div class="nk-header nk-header-fixed is-light">
                    <div class="container-fluid">
                        <div class="nk-header-wrap">
                            <div class="nk-menu-trigger d-xl-none ml-n1 hideOnPrint">
                                <a v-on:click="toggleSidebarMenu()" class="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em class="icon ni ni-menu"></em></a>
                            </div>
                            <div class="nk-header-app-name">
                                <img class="logo-light logo-img" src="@/assets/images/logos/logo-darkbg1.svg" alt="logo-dark">
                                <img class="logo-dark logo-img" src="@/assets/images/logos/logo-lightbg1.svg" alt="logo-dark">
                                <div class="nk-header-news d-none d-xl-block ml-2"><div class="nk-news-list"><router-link class="nk-news-item" :to="{name: 'systemupdates'}"><div class="nk-news-icon"><em class="icon ni ni-card-view"></em></div><div class="nk-news-text"><p>Find out about new modules and features <span> View recent software updates____</span></p><em class="icon ni ni-external"></em></div></router-link></div></div>
                                <!-- <img class="logo-light logo-img" src="require(`@/assets/images/logos/logo-lightbg1.svg`)"  alt="logo">
                                <img class="logo-dark logo-img" src="require(`@/assets/images/logos/logo-darkbg1.svg`)" alt="logo-dark"> -->
                                 <!--<div class="nk-header-app-logo">
                                     <em class="icon ni ni-dashlite bg-secondary-dim"></em> 
                                </div>-->
                                <!-- <div class="nk-header-app-info">
                                    <span class="sub-text">SoftSuite</span>
                                    <span class="lead-text">{{$route.meta.friendlyRouteName}}</span>
                                </div> -->
                            </div>
                            <headertools></headertools>
                        </div>
                    </div>
                </div>
               <sidebarmenu :sidebarMenuOpen="sidebarMenuOpen"></sidebarmenu>
                <div class="nk-content" >
                    <div class="container-fluid">
                        <div class="nk-content-inner">
                            <div class="nk-content-body">
                                <router-view></router-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>

import headertools from '@/components/layout/headertools'
import sidebar from '@/components/layout/sidebar'
import sidebarmenu from '@/components/layout/sidebarmenu'
export default {  
    components: {
        headertools,
        sidebar,
        sidebarmenu,
    },
    data () {
        return {
            sidebarMenuOpen: false
        }
    },
    methods: { 
        toggleSidebarMenu () {
            if (this.sidebarMenuOpen === false) {
                this.sidebarMenuOpen = true
            } else {
                this.sidebarMenuOpen = false
            }
        },
    },
}
</script>

<style>

</style>