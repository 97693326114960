<template>
  <ul class="nk-menu nk-menu-main ui-s2 " style="margin-bottom: 0px;">
    <li class="nk-menu-item has-sub active current-page">
        <a href="#" class="nk-menu-link nk-menu-toggle" data-original-title="" title="">
            <span class="nk-menu-text">Manage</span>
        </a>
        <ul class="nk-menu-sub" style="display: block;">
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'accounts'}" class="nk-menu-link" >
                    <span class="nk-menu-text">Accounts</span>
                </router-link>
            </li>
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'leads'}" class="nk-menu-link" >
                    <span class="nk-menu-text">Leads</span>
                </router-link>
            </li>
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'contacts'}" class="nk-menu-link" >
                    <span class="nk-menu-text">Contacts</span>
                </router-link>
            </li>
            <div style="margin-top: 25px;" v-if="$hasRole('E5C0CC7B-9EA9-42B2-98C0-62B931D21E92') || $hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3') || $hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655')">
                <li class="nk-menu-heading">
                    <h6 class="overline-title text-primary">Internal</h6>
                </li><!-- .nk-menu-item -->
                <li class="nk-menu-item active current-page">
                    <router-link :to="{name: 'users'}" class="nk-menu-link" >
                        <span class="nk-menu-text">Users</span>
                    </router-link>
                </li>
                <li class="nk-menu-item active current-page">
                    <router-link :to="{name: 'teams'}" class="nk-menu-link" >
                        <span class="nk-menu-text">Teams</span>
                    </router-link>
                </li>
            </div>
        </ul><!-- .nk-menu-sub -->
    </li><!-- .nk-menu-item -->
    <li class="nk-menu-item has-sub active current-page" v-if="$hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3') || $hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655')">
        <a href="#" class="nk-menu-link nk-menu-toggle" data-original-title="" title="">
            <span class="nk-menu-text">Settings</span>
        </a>
        <ul class="nk-menu-sub" style="display: block;">
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'settings'}" class="nk-menu-link" >
                    <span class="nk-menu-text">CRM Settings</span>
                </router-link>
            </li>
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'activity'}" class="nk-menu-link" >
                    <span class="nk-menu-text">Sign-In Activity</span>
                </router-link>
            </li>
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'filehistory'}" class="nk-menu-link" >
                    <span class="nk-menu-text">File History</span>
                </router-link>
            </li>
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'assethistory'}" class="nk-menu-link" >
                    <span class="nk-menu-text">Asset History</span>
                </router-link>
            </li>
        </ul><!-- .nk-menu-sub -->
    </li><!-- .nk-menu-item -->
    <!-- <li class="nk-menu-item has-sub active current-page">
        <a href="#" class="nk-menu-link nk-menu-toggle" data-original-title="" title="">
            <span class="nk-menu-text">Billing</span>
        </a>
        <ul class="nk-menu-sub" style="display: block;">
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'invoices'}" class="nk-menu-link" >
                    <span class="nk-menu-text">Invoices</span>
                </router-link>
            </li>
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'usage'}" class="nk-menu-link" >
                    <span class="nk-menu-text">Usage</span>
                </router-link>
            </li>
        </ul>
    </li> -->
    <!-- <li class="nk-menu-item has-sub active current-page">
        <a href="#" class="nk-menu-link nk-menu-toggle" data-original-title="" title="">
            <span class="nk-menu-text">Support</span>
        </a>
        <ul class="nk-menu-sub" style="display: block;">
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'contactsupport'}" class="nk-menu-link" >
                    <span class="nk-menu-text">contact support</span>
                </router-link>
            </li>
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'knowledgebase'}" class="nk-menu-link" >
                    <span class="nk-menu-text">Knowledgebase</span>
                </router-link>
            </li>
        </ul>
    </li> -->
    <li v-if="$hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655')"  class="nk-menu-item has-sub active current-page" >
        <a href="#" class="nk-menu-link nk-menu-toggle" data-original-title="" title="">
            <span class="nk-menu-text">Super Admin</span>
        </a>
        <ul class="nk-menu-sub" style="display: block;">
            <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'tenants'}" class="nk-menu-link" >
                    <span class="nk-menu-text">Tenants</span>
                </router-link>
            </li> 
        </ul><!-- .nk-menu-sub -->
    </li><!-- .nk-menu-item -->
    <li v-if="$hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655')"  class="nk-menu-item has-sub active current-page" >
        <a href="#" class="nk-menu-link nk-menu-toggle" data-original-title="" title="">
            <span class="nk-menu-text">Admin</span>
        </a>
        <ul class="nk-menu-sub" style="display: block;">
            <!-- <li class="nk-menu-item active current-page">
                <router-link :to="{name: 'platformsettings'}" class="nk-menu-link" >
                    <span class="nk-menu-text">Platform Settings</span>
                </router-link>
            </li> -->
        </ul><!-- .nk-menu-sub -->
    </li><!-- .nk-menu-item -->
 </ul><!-- .nk-menu -->
</template>

<script>
export default {

}
</script>

<style>

</style>