<template>
       <div class="nk-sidebar" data-content="sidebarMenu" v-bind:class="{'nk-sidebar nk-sidebar-mobile nk-sidebar-active' : sidebarMenuOpen}">
                    <div class="nk-sidebar-inner" data-simplebar>
                        <ul class="nk-menu nk-menu-md">
                            <li class="nk-menu-heading">
                                <h6 class="overline-title text-white">Apps</h6>
                            </li>
                            <li v-if="$hasTenantModule(1)" class="nk-menu-item">
                                <router-link :to="{ name: 'desk' }" class="nk-menu-link" title="Support Desk">
                                        <span class="nk-menu-icon"><em class="icon ni ni-help-alt"></em></span>
                                        <span class="nk-menu-text">Desk</span>
                                </router-link>
                            </li>
                            <li  class="nk-menu-item">
                                <router-link :to="{ name: 'mycalendar' }" class="nk-menu-link" title="Holidays">
                                    <span class="nk-menu-icon"><em class="icon ni ni-calendar"></em></span>
                                    <span class="nk-menu-text">Calendar</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(2)" class="nk-menu-item">
                                <router-link :to="{ name: 'docs' }" class="nk-menu-link" title="Documents">
                                    <span class="nk-menu-icon"><em class="icon ni ni-folder"></em> </span>
                                    <span class="nk-menu-text">Documents</span>
                                </router-link>
                            </li>
                            <li class="nk-menu-heading">
                               <h6 class="overline-title text-white">Comms</h6>
                            </li>
                            <li  class="nk-menu-item">
                                <router-link :to="{ name: 'docs' }" class="nk-menu-link" title="Documents">
                                    <span class="nk-menu-icon"><em class="icon ni ni-chat-circle"></em> </span>
                                    <span class="nk-menu-text">Messages</span>
                                </router-link>
                            </li>
                            <li  class="nk-menu-item">
                                <router-link :to="{ name: 'docs' }" class="nk-menu-link" title="Documents">
                                    <span class="nk-menu-icon"><em class="icon ni ni-bell"></em> </span>
                                    <span class="nk-menu-text">Notifications</span>
                                </router-link>
                            </li>
                        </ul> 
                    </div>
                </div>
</template>

<script>
export default {
props: { sidebarMenuOpen: Boolean }
}
</script>

<style>

</style>