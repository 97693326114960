<template>
  <div class="nk-sidebar-element">
                <div class="nk-sidebar-body">
                    <div class="nk-sidebar-content" data-simplebar>
                        <div class="nk-sidebar-menu">
                            <!-- Menu -->
                            <ul class="nk-menu apps-menu">
                                <li class="nk-menu-item">
                                    <router-link :to="{name: $store.state.userSettings.dashboard}" class="nk-menu-link" title="Dashboard">
                                        <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span>
                                    </router-link>
                                </li>
                                <li v-if="$hasTenantModule(1) && $hasRole('FFDD41FC-4800-41E4-9F38-1C1ABAE132EE')" class="nk-menu-item">
                                    <router-link :to="{ name: 'desk' }" class="nk-menu-link" title="Support Desk">
                                            <span class="nk-menu-icon"><em class="icon ni ni-help-alt"></em></span>
                                    </router-link>
                                </li>
                                <li v-if="$hasTenantModule(3) && $hasRole('BB5C89B3-CFEC-49F7-A0D1-A05F0F77EFDE') && ($hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3') || $hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655'))">
                                    <router-link :to="{ name:'employees'}" class="nk-menu-link" title="Employees">
                                        <span class="nk-menu-icon"><em class="icon ni ni-user-alt"></em></span>
                                    </router-link>
                                </li>
                                <li v-if="$hasTenantModule(4) && $hasRole('B69068C7-A112-41F4-9572-96380C592231') && ($hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3') || $hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655'))">
                                    <router-link :to="{ name:'assetsmanager'}" class="nk-menu-link" title="Assets Manager">
                                        <span class="nk-menu-icon"><em class="icon ni ni-package-fill"></em></span>
                                    </router-link>
                                </li>
                                <li  class="nk-menu-item">
                                    <router-link :to="{ name: 'mycalendar' }" class="nk-menu-link" title="Holidays">
                                        <span class="nk-menu-icon"><em class="icon ni ni-calendar"></em></span>
                                    </router-link>
                                </li>
                                <li v-if="$hasTenantModule(2) && $hasRole('5CF85762-2581-48BA-9D3E-C54295B1B597')" class="nk-menu-item">
                                    <router-link :to="{ name: 'docs' }" class="nk-menu-link" title="Documents">
                                        <span class="nk-menu-icon"><em class="icon ni ni-folder"></em></span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="nk-sidebar-footer">
                            <ul class="nk-menu nk-menu-md">
                                <li class="nk-menu-link">
                                    <a class="nk-menu-link" title="Settings">
                                        <router-link :to="{name: 'mysettings'}" class="nk-menu-link"> 
                                            <span class="nk-menu-icon"><em class="icon ni ni-setting"></em></span>
                                        </router-link>
                                    </a>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                    <div class="nk-sidebar-profile nk-sidebar-profile-fixed dropdown" v-if="$store.state.userSettings.email === 'test@softsuite.co.uk'">
                        <router-link :to="{name: 'devteam'}"><div class="user-avatar">
                                <span>U</span>
                            </div></router-link>
                        <!-- <a href="#" data-toggle="dropdown" data-offset="50,-60">
                            <div class="user-avatar">
                                <span>U</span>
                            </div>
                        </a> -->
                        <div class="dropdown-menu dropdown-menu-md ml-4">
                            <div class="dropdown-inner user-card-wrap d-none d-md-block">
                                <div class="user-card">
                                    <div class="user-avatar">
                                        <span>U</span>
                                    </div>
                                    <div class="user-info">
                                        <span class="lead-text">Neil Hewitt</span>
                                        <span class="sub-text text-soft">info@softsuite.co.uk</span>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-inner">
                                <ul class="link-list">
                                    <li><a href="profile"><em class="icon ni ni-user-alt"></em><span>View Profile</span></a></li>
                                    <li><a href="Settings"><em class="icon ni ni-setting-alt"></em><span>Account Setting</span></a></li>
                                    <li><a href="activity"><em class="icon ni ni-activity-alt"></em><span>Login Activity</span></a></li>
                                </ul>
                            </div>
                            <div class="dropdown-inner">
                                <ul class="link-list">
                                    <li><a href="#"><em class="icon ni ni-signout"></em><span>Sign out</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
</template>

<script>
export default {
    data() {
        return {
        
        }
    },
    methods: {
    }
}
</script>

<style>
    ul {
        font-family: sans-serif;
    }
</style>