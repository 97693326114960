import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userSettings: {id:1, tenantId:11, isAuthed: false},
		frontEndSettings: {isLoading: false},
		changes: {ticketChanges: []},
		filterSettings: {
			filteredTicketsByTitle: '',
			filteredTicketsByStatusId: null,
			filteredTicketsByPriorityId: null,
			filteredTicketsByAssignedUserId: null,
			filteredTicketsByActiveWorking: null
		}
	},
	mutations: {
		updateUserSettings (state, settings) {
			state.userSettings = settings 
		},
		updateFSIsLoading (state, isLoading) {
			state.frontEndSettings.isLoading = isLoading
		},
		updateChanges (state, changes) {
			state.changes = changes
		},
		addTicketChange (state, change) {
			state.changes.ticketChanges.push(change)
		},
		setFilteredTitleTickets (state, value) {
			state.filterSettings.filteredTicketsByTitle = value
		},
		setFilteredStatusTickets (state, value) {
			state.filterSettings.filteredTicketsByStatusId = value
		},
		setFilteredPriorityTickets (state, value) {
			state.filterSettings.filteredTicketsByPriorityId = value
		},
		setFilteredAssignedUserTickets (state, value) {
			state.filterSettings.filteredTicketsByAssignedUserId = value
		},
		setFilteredActiveWorkingTickets (state, value) {
			state.filterSettings.filteredTicketsByActiveWorking = value
		}
	},
	actions: {
	},
	modules: {
	}
})
