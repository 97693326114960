<template>
            <li v-on:click="toggleNotifications()" class="dropdown notification-dropdown">
                <a href="#" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                    <div class="icon-status icon-status-info"><em class="icon ni ni-bell"></em></div>
                </a>
                <div @mouseover="showNotifications = true" @mouseleave="showNotifications = false" class="dropdown-menu dropdown-menu-xl dropdown-menu-right" v-bind:class="{'show' : showNotifications}">
        <div class="dropdown-head">
            <span class="sub-title nk-dropdown-title">Notifications</span>
            <a href="#">Mark All as Read</a>
        </div>
        <div class="dropdown-body">
            <div class="nk-notification">
                <div class="nk-notification-item dropdown-inner">
                    <div class="nk-notification-icon">
                        <em class="icon icon-circle bg-primary-dim ni ni-share"></em>
                    </div>
                    <div class="nk-notification-content">
                        <div class="nk-notification-text">James shared <span>SoftSuite</span> with you.</div>
                        <div class="nk-notification-time">Just now</div>
                    </div>
                </div>
                <div class="nk-notification-item dropdown-inner">
                    <div class="nk-notification-icon">
                        <em class="icon icon-circle bg-info-dim ni ni-edit"></em>
                    </div>
                    <div class="nk-notification-content">
                        <div class="nk-notification-text">Matt Ellis <span>invited</span> you to edit <span>SoftSuite</span> folder</div>
                        <div class="nk-notification-time">2 hrs ago</div>
                    </div>
                </div>
                <div class="nk-notification-item dropdown-inner">
                    <div class="nk-notification-icon">
                        <em class="icon icon-circle bg-primary-dim ni ni-share"></em>
                    </div>
                    <div class="nk-notification-content">
                        <div class="nk-notification-text">You have shared <span>project v2</span> with James.</div>
                        <div class="nk-notification-time">7 days ago</div>
                    </div>
                </div>
                <div class="nk-notification-item dropdown-inner">
                    <div class="nk-notification-icon">
                        <em class="icon icon-circle bg-success-dim ni ni-spark"></em>
                    </div>
                    <div class="nk-notification-content">
                        <div class="nk-notification-text">Your <span>Subscription</span> renew successfully.</div>
                        <div class="nk-notification-time">2 month ago</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dropdown-foot center">
            <a href="#">View All</a>
        </div>
                </div>
            </li>
</template>

<script>
export default {
    data () {
        return {
            showNotifications: false,
        }
    },
methods:{
    closeList() {
              this.showNotifications  = false
        },
    toggleNotifications () {
        if (this.showNotifications) {
            this.showNotifications  = false
        } else {
            this.showNotifications  = true
            this.$emit('openNotifications')
        }
    },
    getNotification(){
        alert('TODO:- get new notifications based on last int.')
    }
},
}
</script>

<style>

</style>