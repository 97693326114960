<template>
    <div>
        <div class="nk-app-root">
        <div class="nk-main ">
            <div class="nk-wrap ">
                <div class="nk-header nk-header-fixed is-light">
                    <div class="container-fluid">
                        <div class="nk-header-wrap">
                            <div class="nk-menu-trigger ml-n1 hideOnPrint">
                                <a v-on:click="toggleSidebarMenu()" class="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em class="icon ni ni-menu"></em></a>
                            </div>
                            <div class="nk-header-app-name">
                                <img class="logo-light logo-img" src="@/assets/images/logos/logo-darkbg1.svg" alt="logo-dark">
                                <img class="logo-dark logo-img" src="@/assets/images/logos/logo-lightbg1.svg" alt="logo-dark">
                                <!--<div class="nk-header-app-logo">
                                     <em class="icon ni ni-dashlite bg-secondary-dim"></em> 
                                </div>-->
                                <!-- <div class="nk-header-app-info">
                                    <span class="sub-text">SoftSuite</span>
                                    <span class="lead-text">{{$route.meta.friendlyRouteName}}</span>
                                </div> -->
                            </div>
                            <headertools></headertools>
                        </div>
                    </div>
                </div>
               <sidebarmenu :sidebarMenuOpen="sidebarMenuOpen"></sidebarmenu>
                <router-view></router-view>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import headertools from '@/components/layout/v2user/headertools'
import sidebarmenu from '@/components/layout/v2user/sidebarmenu'
export default {  
    components: {
        headertools,
        sidebarmenu,
    },
    data () {
        return {
            sidebarMenuOpen: false
        }
    },
    methods: {
        toggleSidebarMenu () {
            if (this.sidebarMenuOpen === false) {
                this.sidebarMenuOpen = true
            } else {
                this.sidebarMenuOpen = false
            }
        },
    },
}
</script>

<style>

</style>