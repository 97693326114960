<template>
    <div class="nk-header-tools">
        <ul class="nk-quick-nav hideOnPrint">
            <em class="icon ni ni-search"/>
            <searchbar /> 
            <activeTickets v-if="$hasTenantModule(1)"/>
            <messages @openMessages="openMessages()" ref="messageComponent" />
            <!-- <notifications @openNotifications="openNotifications()" ref="notificationsComponent"/> -->
            <li class="dropdown list-apps-dropdown">
                <a v-on:click="toggleAppMenu()"  class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                    <div class="icon-status icon-status-na"><em class="icon ni ni-menu-circled"></em></div>
                </a>
                <div @mouseover="showAppMenu = true" @mouseleave="showAppMenu = false" class="dropdown-menu dropdown-menu-lg dropdown-menu-right" v-bind:class="{'show' : showAppMenu}">
                    <div class="dropdown-body">
                        <ul class="list-apps">
                            <li>
                                <router-link :to="{name: $store.state.userSettings.dashboard}">
                                    <span class="list-apps-media"><em class="icon ni ni-dashlite bg-primary text-white"></em></span>
                                    <span class="list-apps-title">Dashboard</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'mymessages' }">
                                    <span class="list-apps-media"><em class="icon ni ni-chat-circle bg-info-dim"></em></span>
                                    <span class="list-apps-title">Messages</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(1) && $hasRole('FFDD41FC-4800-41E4-9F38-1C1ABAE132EE')">
                                <router-link :to="{ name: 'tickets' }">
                                    <span class="list-apps-media"><em class="icon ni ni-ticket bg-danger-dim"></em></span>
                                    <span class="list-apps-title">Tickets</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(2) && $hasRole('5CF85762-2581-48BA-9D3E-C54295B1B597')">
                                <router-link :to="{ name: 'docs' }">
                                    <span class="list-apps-media"><em class="icon ni ni-folder bg-azure-dim"></em></span>
                                    <span class="list-apps-title">Documents</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(3) && ($hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3') || $hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655'))">
                                <router-link :to="{ name: 'people' }">
                                    <span class="list-apps-media"><em class="icon ni ni-users bg-blue-dim"></em></span>
                                    <span class="list-apps-title">People</span>
                                </router-link>
                            </li>
                            <!-- <li>
                                <router-link :to="{ name: 'inet' }">
                                    <span class="list-apps-media"><em class="icon ni ni-share-alt bg-pink-dim"></em></span>
                                    <span class="list-apps-title">iNet</span>
                                </router-link>
                            </li> -->
                            <li v-if="$hasTenantModule(1) && $hasRole('FFDD41FC-4800-41E4-9F38-1C1ABAE132EE')">
                                <router-link :to="{ name: 'desk' }">
                                    <span class="list-apps-media"><em class="icon ni ni-help-alt bg-purple-dim"></em></span>
                                    <span class="list-apps-title">Desk</span>
                                </router-link>
                            </li>
                            <!-- <li>
                                <router-link :to="{ name: 'dashboards' }">
                                    <span class="list-apps-media"><em class="icon ni ni-menu-circled bg-success-dim"></em></span>
                                    <span class="list-apps-title">Dashboards</span>
                                </router-link>
                            </li> -->
                            <li v-if="$hasTenantModule(4) && $hasRole('B69068C7-A112-41F4-9572-96380C592231') && ($hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3') || $hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655'))">
                                <router-link :to="{ name: 'assetsdashboard' }">
                                    <span class="list-apps-media"><em class="icon ni ni-package-fill bg-danger-dim"></em></span>
                                    <span class="list-apps-title">Assets</span>
                                </router-link>
                            </li>
                            <li v-if="$hasRole('8B4EF664-3188-4B58-917B-14DF36FDF9DA')">
                                <router-link :to="{ name: 'crm' }">
                                    <span class="list-apps-media"><em class="icon ni ni-building bg-light-dim"></em></span>
                                    <span class="list-apps-title">CRM</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(1) && $hasRole('FFDD41FC-4800-41E4-9F38-1C1ABAE132EE') && ($hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3') || $hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655'))">
                                <router-link :to="{ name: 'reports' }">
                                    <span class="list-apps-media"><em class="icon ni ni-growth bg-success-dim"></em></span>
                                    <span class="list-apps-title">Desk Reports</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(5) && $hasRole('23F7DFCE-24C3-4C7F-90C4-A9CCE258D913')">
                                <router-link :to="{ name: 'projects' }">
                                    <span class="list-apps-media"><em class="icon ni ni-grid-plus-fill bg-warning-dim"></em></span>
                                    <span class="list-apps-title">Projects</span>
                                </router-link>
                            </li>
                            <li v-if="$hasTenantModule(6) && $hasRole('c8fb917c-74c6-4998-a10e-c7ffddfb8a01')">
                                <router-link :to="{ name: 'irrigationreport' }">
                                    <span class="list-apps-media"><em class="icon ni ni-pie-fill bg-danger-dim"></em></span>
                                    <span class="list-apps-title">Irrigation Report</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li   class="dropdown user-dropdown">
                <a v-on:click="toggleMenu"   class="dropdown-toggle mr-n1" data-toggle="dropdown">
                    <div class="user-toggle">
                        <div class=" sm">
                            <avatar :src="$store.state.userSettings.avatarURI" :size="32" :rounded="true" v-if="$store.state.userSettings.firstName" :username="$store.state.userSettings.firstName + ' ' + $store.state.userSettings.lastName" />
                        </div>
                    </div>
                </a>
                <div @mouseover="showMenu = true" @mouseleave="showMenu = false" class="dropdown-menu dropdown-menu-md dropdown-menu-right" v-bind:class="{'show' : showMenu}">
                    <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                        <div class="user-card">
                            <div class="" style="padding: 0px 10px;">
                                    <avatar :src="$store.state.userSettings.avatarURI" :size="32" :rounded="true" v-if="$store.state.userSettings.firstName" :username="$store.state.userSettings.firstName + ' ' + $store.state.userSettings.lastName" />
                            </div>
                            <div class="user-info">
                                <span class="lead-text">{{$store.state.userSettings.firstName + ' ' + $store.state.userSettings.lastName}}</span>
                                <span class="sub-text">{{$store.state.userSettings.email}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-inner">
                        <ul class="link-list">
                            <li><router-link :to="{name: 'myprofile'}" ><em class="icon ni ni-user-alt"></em><span>Profile</span></router-link></li>
                            <!-- <li><router-link :to="{name: 'mysettings'}" ><em class="icon ni ni-setting-alt"></em><span>Account Setting</span></router-link></li> -->
                            <li><router-link :to="{name: 'myactivity'}" ><em class="icon ni ni-activity-alt"></em><span>Login Activity</span></router-link></li>
                            <li><a class="dark-switch" v-on:click="toggleDarkMode()" ><em class="icon ni ni-moon"></em><span>Dark Mode</span></a></li>
                        </ul>
                    </div>
                    <div class="dropdown-inner">
                        <ul class="link-list">
                            <li><a v-on:click="logOut()"><em class="icon ni ni-signout"></em><span>Sign out</span></a></li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div><!--x-->
</template>

<script>
// import notifications from '@/components/notifications/index.vue'
import messages from '@/components/messages/index.vue'
import searchbar from './searchBar.vue'
import activeTickets from '@/components/desk/tickets/activeTickets.vue'
export default {
    components: {  messages, searchbar, activeTickets  },
    data () {
        return {
            showNotifications: false,
            showChats: false,
            showMenu: false,
            showAppMenu: false,
            darkMode: false,
            searchTerm: ''
        }
    },
methods:{
    logOut () {
      localStorage.removeItem('trimToken')
      this.$router.push('/login')
    },
    
    // toggleChats () {
    //     this.showNotifications = false
    //     this.showMenu = false
    //     this.showAppMenu = false
    //     if (this.showChats) {
    //         this.showChats  = false
    //     } else {
    //         this.showChats  = true
    //     }
    // },
    closeNotifications () {
        // this.$refs.notificationsComponent.closeList()
    },
    closeMessages () {
        this.$refs.messageComponent.closeList()
    },
    closeAppMenu () {
         this.showAppMenu  = false
    },
    closeMenu () {
         this.showMenu  = false
    },
    openNotifications () {
        this.$refs.messageComponent.closeList()
        this.showAppMenu  = false
        this.showMenu  = false
    },
    openMessages () {
        // this.$refs.notificationsComponent.closeList()
        this.showAppMenu  = false
        this.showMenu  = false
    },
    openAppMenu () {
        // this.$refs.notificationsComponent.closeList()
        this.$refs.messageComponent.closeList()
        this.showMenu  = false
    },
    openMenu () {
        this.$refs.notificationsComponent.closeList()
        this.$refs.messageComponent.closeList()
        this.showAppMenu  = false
    },
    toggleAppMenu() {
    //    this.closeMessages()
    //    this.closeNotifications()
    //    this.closeMenu()
    //    this.showChats = false
    //    this.showNotifications = false
    if (this.showAppMenu) {
            this.showAppMenu  = false
        } else {
            this.openAppMenu()
            this.showAppMenu  = true
        }
    },
    // toggleNotifications () {
    //     this.showChats = false
    //     this.showMenu = false
    //     this.showAppMenu = false
    //     if (this.showNotifications) {
    //         this.showNotifications  = false
    //     } else {
    //         this.showNotifications  = true
    //     }
    // },
    toggleMenu () {
        // this.closeMessages()
        // this.closeNotifications()
        // this.closeAppMenu()
        if (this.showMenu) {
            this.showMenu  = false
        } else {
            this.showMenu  = true
            this.openMenu()
        }
    }  ,
    toggleDarkMode() {
    const el = document.body;

    if (this.darkMode === false) {
        this.darkMode = true
        el.classList.add('dark-mode');
    } else {
        this.darkMode = false
        el.classList.remove('dark-mode');
    }
  },

},
}
</script>

<style >

</style>