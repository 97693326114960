<template>
  <div>
        <a v-on:click="toggleActiveTickets()" class="dropdown-toggle nk-quick-nav-icon " data-toggle="dropdown">
            <div :class="{'icon-status-info' : hasActiveTimer, 'icon-status-na' : !hasActiveTimer}" class="icon-status "><em class="icon ni ni-ticket"></em></div>
        </a>
        <a-modal v-model="showModal" width="60%" title="My Active Ticket Timers">
            <myActiveTicketTimeAllocation :activeTimers="activeTimers"/>
        </a-modal>
  </div>
</template>

<script>
import myActiveTicketTimeAllocation from './myTicketTimeAllocation.vue'

export default {
    components: {
        myActiveTicketTimeAllocation
    },
    data() {
        return {
            showModal: false,
            hasActiveTimer: false,
            activeTimers: []
        }
    },
    created() {
        this.getMyActiveTimers()
        this.setRefresh()
    },
    methods: {
        toggleActiveTickets() {
            this.showModal = true
        },
        getMyActiveTimers() {
            this.$http.get('/desk/Get_My_Active_User_TimeAllocations')
            .then((response) => {
                this.activeTimers = response.data
                if (this.activeTimers.length) {
                    this.hasActiveTimer = true
                } else {
                    this.hasActiveTimer = false
                }
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        setRefresh() {
            setInterval(() => {
                this.getMyActiveTimers()
            }, 15000) // every 15 seconds
        }
    }
}
</script>

<style>

</style>