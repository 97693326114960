<template>
  <div id="app">
     <!-- Loader -->
     <transition name="fade" mode="out-in">
      <div id="loading" v-if="$store.state.frontEndSettings.isLoading">
          <div id="loading-center">
              <img style="width:36px; height:auto;" src="@/assets/images/loader.gif" alt="loader">
          </div>
      </div>
    </transition>
    <div v-if="isImpersonating" style="height: 14px;
    width: 100%;
    background-color: #FF4560;
    position: absolute; 
    z-index:1101;
    padding-left:10px;
    ">
     <div style="font-size:9px; color: #fff; "> Impersonating {{$store.state.userSettings.firstName + ' ' + $store.state.userSettings.lastName }} - <a v-on:click="resetImpersonating()"><strong>Click HERE to CANCEL IMPERSONATING</strong></a></div>
    </div>
    <component v-bind:is="layout"></component>
 
  </div>
</template>
<script>
import crmLayout from './layouts/crmlayout.vue'
import defaultAppLayoutSlim from './layouts/defaultappslim.vue'
import defaultAppLayout from './layouts/defaultapp.vue'
import blankLayout from './layouts/blank.vue'
import v2userapplayout from './layouts/v2userapplayout.vue'
import projectlayout from './layouts/projectlayout.vue'

// import './assets/css/main-style.css'
// import './assets/css/theme.css'

export default {
  methods:{
    resetImpersonating(){
      localStorage.trimToken = localStorage.originTrimToken  
      localStorage.removeItem('originTrimToken') 
      this.getUserSettings() 
    },
    getUserSettings(){
       this.$http.get('/users/Get_UserSettings/')
            .then((response) => {
              this.$store.commit('updateUserSettings', response.data) 
               window.location.href = window.location.origin + window.location.pathname;
              //this.$router.push({ name: 'myprofile', query: { forceReload: true } })
            })
            .catch((error) => {
              alert(error)
            })
    }
  },
  created() {
    // alert('need to start signalR')
     this.$socket.start({
       log: false // Active only in development for debugging.
     })// moved additionals to 
    //  .then(res => {
    //       this.$socket.invoke("JoinTenantsGroup", "groupName")  //JoinGroup is C# method name
    //       console.log(res)
    //    .catch(err => {
    //         console.log(err)
    //     })
    //   }).catch(err => {
    //               console.log(err)
    // });
     
  },
  sockets: { 
    // this is signalR extension see https://mattmillican.com/blog/signalr-with-vue
    // any socket endpoints used in the application need to be added here. 
      ticketAdded () {},
      ticketStatusUpdated () {},
      ticketUpdated () {
      // this has to be here even though it's not being used?
      // otherwise other events don't actually fire
    }
  },
  computed: {
    layout () {
      return  this.$route.meta.layout
    },
    isImpersonating(){  
      return  localStorage.getItem('originTrimToken') ? true : false
    }
  },
  components: {
    'default-project-layout': projectlayout ,
    'v2userapplayout': v2userapplayout ,
    'default-app-slim': defaultAppLayoutSlim ,
    'crm-layout': crmLayout ,
    'blank-layout': blankLayout ,
    'default-app-layout': defaultAppLayout
    // define as many layouts you want for the application
  }
}
</script>
<style>
/* vue transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* end vue transitions */
</style>
