import { render, staticRenderFns } from "./defaultapp.vue?vue&type=template&id=046a1871&"
import script from "./defaultapp.vue?vue&type=script&lang=js&"
export * from "./defaultapp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports