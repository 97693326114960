import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from './services/vue-axios/axios.js'
import VueAxios from 'vue-axios'
import draggable from 'vuedraggable'
import VCalendar from 'v-calendar'
import VueApexCharts from 'vue-apexcharts'
import VueGoodTablePlugin from 'vue-good-table'
import Avatar from 'vue-avatar'
import moment from 'moment'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import VSwatches from 'vue-swatches'
import VoerroTagsInput from '@voerro/vue-tagsinput'
import Multiselect from 'vue-multiselect'
import VueSignalR from '@latelier/vue-signalr'
import VueDragscroll from 'vue-dragscroll'
import VueExcelEditor from 'vue-excel-editor'
import Vuelidate from 'vuelidate'
import UUID from 'vue-uuid'
import _ from 'lodash'
import VueInputAutowidth from 'vue-input-autowidth'
import Antd from 'ant-design-vue';
import {
  notification, message
} from 'ant-design-vue'
import JsonCSV from 'vue-json-csv'



// import {
//   notification, message,TreeSelect, Rate, Breadcrumb, InputNumber, Steps,
//   Upload, Button, Layout, Table, Icon, Progress, Radio, Dropdown, Menu,
//   Carousel, Input, Calendar, Badge, Slider, Form, FormModel, Tooltip, Select, Switch,
//   Tag, Affix, Spin, Alert, Checkbox, Tabs, Pagination, Drawer,
//   Cascader, DatePicker, TimePicker, Divider, Anchor, AutoComplete, BackTop, Collapse, Card, List, Popover,
//   Tree, Timeline, Row, Col, Transfer, Modal, Popconfirm, Skeleton, LocaleProvider, ConfigProvider, Descriptions, Statistic,
// } from 'ant-design-vue'

import 'vue-good-table/dist/vue-good-table.css'
import 'ant-design-vue/dist/antd.css'
import 'vue-swatches/dist/vue-swatches.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './assets/css/main-style.css'
import './assets/css/theme.css'

Vue.component('multiselect', Multiselect)
Vue.component('vueswatches',VSwatches)
Vue.component('tags-input', VoerroTagsInput);
Vue.component('downloadCsv', JsonCSV)

Vue.use(VueInputAutowidth)
Vue.use(VueExcelEditor)
Vue.use(VueGoodTablePlugin)
Vue.use(VueApexCharts)
Vue.use(VueDragscroll)
Vue.component('apexchart', VueApexCharts)
Vue.component('avatar', Avatar)

Vue.use(VCalendar, {
  componentPrefix: 'vc', 
});
Vue.use(VueAxios, axios)
Vue.use(axios)
Vue.use(draggable)
Vue.use(Antd)
// antdv items --  we should review and remove some of these before release to reduce files size..
Vue.use(message)
Vue.use(notification)
// Vue.use(ConfigProvider)
// Vue.use(Skeleton)
// Vue.use(Popconfirm)
// Vue.use(Transfer)
// Vue.use(Modal)
// Vue.use(Divider)
// Vue.use(Row)
// Vue.use(Col)
// Vue.use(Timeline)
// Vue.use(Tree)
// Vue.use(Popover)
// Vue.use(List)
// Vue.use(Card)
// Vue.use(Button)
// Vue.use(Rate)
// Vue.use(TreeSelect)
// Vue.use(Breadcrumb)
// Vue.use(Layout)
// Vue.use(Table)
// Vue.use(Icon)
// Vue.use(Progress)
// Vue.use(Radio)
// Vue.use(Dropdown)
// Vue.use(Menu)
// Vue.use(Carousel)
// Vue.use(Input)
// Vue.use(Calendar)
// Vue.use(Badge)
// Vue.use(Slider)
// Vue.use(Form)
// Vue.use(FormModel)
// Vue.use(Tooltip)
// Vue.use(Select)
// Vue.use(Tag)
// Vue.use(Affix)
// Vue.use(Spin)
// Vue.use(Alert)
// Vue.use(Checkbox)
// Vue.use(Tabs)
// Vue.use(Pagination)
// Vue.use(Upload)
// Vue.use(Steps)
// Vue.use(InputNumber)
// Vue.use(Drawer)
// Vue.use(Switch)
// Vue.use(Cascader)
// Vue.use(DatePicker)
// Vue.use(TimePicker)
// Vue.use(Anchor)
// Vue.use(AutoComplete)
// Vue.use(Collapse)
// Vue.use(BackTop)
// Vue.use(LocaleProvider)
// Vue.use(Descriptions)
// Vue.use(Statistic)
// end of antDV

Vue.use(Vuelidate)
Vue.use(UUID)
Vue.use(_)

let host  =  (window.location.href.indexOf('localhost') >= 0) ? 'http://localhost:7672' :  (window.location.href.indexOf('test.') >= 0) ? 'https://testapi.softsuite.app' : 'https://api.softsuite.app'
Vue.use(VueSignalR, host + '/broadcast/') //'http://localhost:7672/signalr/hubs/broadcast')

Vue.prototype.$notification = notification
Vue.prototype.$message = message
Vue.prototype.$moment = moment
Vue.prototype.$displaySoftsuiteLoading = false
Vue.mixin({
  methods: {
    // validate if the user has this role.
    $hasRole (allowedRole) { // returns if either array has a matching Id and therefore allowed.
      let hasRole = false
      if (this.$store.state.userSettings.roles.filter(function(e) { return e.id.toLowerCase() === allowedRole.toLowerCase(); }).length > 0) {
        hasRole = true
      } else {
        hasRole = false
      }
      return hasRole
    },
    $hasTenantModule (module) { // returns if either array has a matching Id and therefore allowed.
      var arrayB = this.$store.state.userSettings.modules
      let exists = false
        arrayB.forEach(bel => {
          if (module  === bel.moduleId) {
            exists =  true
          }
        })
      return exists
    },
  },
})

// Vue.prototype.$hasRole = function hasRole(roles) {
//                           var arrayA = roles.split(',')
//                           var arrayB = store.state.userSettings.modules
//                           arrayA.forEach(ael => {
//                             arrayB.forEach(bel => {
//                               if (ael.id  === bel.id) {
//                                 return true
//                               }
//                             });
//                           });
//                           return false
//                         }
Vue.prototype.$hasModule  = function(roles) {
   var arrayA = roles.split(',')
   var arrayB = store.state.userSettings.modules
   arrayA.forEach(ael => {
     arrayB.forEach(bel => {
       if (ael  === bel.id) {
         return true
       }
     });
   });
   return false
}
//(roles) => {
 // console.log(roles)
  //return false
  // var arrayA = roles.split(',')
  // var arrayB = store.state.userSettings.modules
  // arrayA.forEach(ael => {
  //   arrayB.forEach(bel => {
  //     if (ael  === bel.id) {
  //       return true
  //     }
  //   });
  // });
  // return false
//}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
