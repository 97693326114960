import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import DefaultLayout from '../layout/default.vue '

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/dashboards/' + store.state.userSettings.dashboard,
  //   meta: { 
  //     layout: 'blank-layout', 
  //     friendlyRouteName: 'Base' ,
  //     authRequired: true,
  //   },
  // },
  {
    path: '/',
    // redirect: wheresMyDash(),
    meta: { 
      layout: 'blank-layout', 
      friendlyRouteName: 'dashboardswitch' ,
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { 
      layout: 'blank-layout', 
      friendlyRouteName: 'Login' ,
      authRequired: false,
    },
    component: () => import('@/views/auth/login'),
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    meta: { 
      layout: 'blank-layout', 
      friendlyRouteName: 'unauthorized' ,
      authRequired: false,
    },
    component: () => import('@/views/auth/unauthorized'),
  },
  {
    path: '/pages/privacypolicy',
    name: 'privacypolicy',
    meta: { 
      layout: 'blank-layout', 
      friendlyRouteName: 'Privacy Policy' ,
      authRequired: false,
    },
    component: () => import('@/views/pages/privacypolicy'),
  },
  {
    path: '/pages/termsandconditions',
    name: 'termsandconditions',
    meta: { 
      layout: 'blank-layout', 
      friendlyRouteName: 'Terms And Conditions' ,
      authRequired: false,
    },
    component: () => import('@/views/pages/termsandconditions'),
  },
  {
    path: '/pages/help',
    name: 'help',
    meta: { 
      layout: 'blank-layout', 
      friendlyRouteName: 'Help' ,
      authRequired: false,
    },
    component: () => import('@/views/pages/help'),
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    meta: { 
      layout: 'blank-layout', 
      friendlyRouteName: 'Welcome Aboard' ,
      authRequired: false,
    },
    component: () => import('@/views/auth/onboarding'),
  },
  {
    path: '/confirmpasswordreset',
    name: 'confirmpasswordreset',
    meta: { 
      layout: 'blank-layout', 
      friendlyRouteName: 'Confirm Password Reset' ,
      authRequired: false,
    },
    component: () => import('@/views/auth/confirmpasswordreset'),
  },
  {
    path: '/passwordreset',
    name: 'passwordreset',
    meta: { 
      layout: 'blank-layout', 
      friendlyRouteName: 'Password Reset' ,
      authRequired: false,
    },
    component: () => import('@/views/auth/passwordreset'),
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'admin' ,
      authRequired: false,
    },
    component: () => import('@/views/admin'),
  },
  {
    path: '/tenants',
    name: 'tenants',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'tenants' ,
      authRequired: false,
    },
    component: () => import('@/views/admin/tenants'),
  },
  {
    path: '/tenant',
    name: 'tenant',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'tenant' ,
      authRequired: false,
    },
    component: () => import('@/views/admin/tenants/tenant'),
  },
  {
    path: '/my/notifications',
    name: 'mynotifications',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'My Notifications' ,
      authRequired: true,
    },
    component: () => import('@/views/my/notifications'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Dashboard' ,
      authRequired: true,
    },
    component: () => import('@/views/dashboard'),
  },
  {
    path: '/dashboards/goddashboard',
    name: 'goddashboard',
    meta: {
      layout: 'default-app-layout', 
      friendlyRouteName: 'God Dashboard' ,
      authRequired: true,
    },
    component: () => import('@/views/dashboards/goddashboard'),
  },
  {
    path: '/dashboards/userdashboard',
    name: 'userdashboard',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'User Dashboard' ,
      authRequired: true,
    },
    component: () => import('@/views/dashboards/userdashboard'),
  },
  {
    path: '/dashboards/admindashboard',
    name: 'admindashboard',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Admin Dashboard' ,
      authRequired: true,
    },
    component: () => import('@/views/dashboards/admindashboard'),
  },
  {
    path: '/inet',
    name: 'inet',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Intranet' ,
      authRequired: true,
    },
    component: () => import('@/views/inet'),
  },
  {
    path: '/docs/documentviewer',
    name: 'documentviewer',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Document Viewer' ,
      authRequired: true,
    },
    component: () => import('@/views/docs/documentviewer'),
  },
  {
    path: '/plan',
    name: 'plan',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Planner' ,
      authRequired: true,
    },
    component: () => import('@/views/plan'),
  },
  {
    path: '/users',
    name: 'users',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'users' ,
      authRequired: true,
    },
    component: () => import('@/views/users'),
  },
  {
    path: '/messages',
    name: 'messages',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Messages Dashboard' ,
      authRequired: true,
    },
    component: () => import('@/views/messages'),
  },
  {
    path: '/validTest',
    name: 'testvalidation',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Messages Dashboard' ,
      authRequired: true,
    },
    component: () => import('@/views/test/validationTest'),
  },
  {
    path: '/holiday',
    name: 'holiday',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Holiday Dashboard' ,
      authRequired: true,
    },
    component: () => import('@/views/holiday'),
  },
  {
    path: '/holiday/requests',
    name: 'holidayrequests',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Requests' ,
      authRequired: true,
    },
    component: () => import('@/views/holiday/requests'),
  },
  {
    path: '/admin/platformsettings',
    name: 'platformsettings',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Platform Settings' ,
      authRequired: true,
    },
    component: () => import('@/views/admin/platformsettings'),
  },
  {
    path: '/docs',
    name: 'docs',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Document Manager' ,
      authRequired: true,
    },
    props: true,
    component: () => import('@/views/docs'),
  },
  {
    path: '/dashboards',
    name: 'dashboards',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Dashboards Manager' ,
      authRequired: true,
    },
    component: () => import('@/views/dashboards'),
  },
  {
    path: '/dashboards/designer',
    name: 'dashboarddesigner',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Dashboards Manager' ,
      authRequired: true,
    },
    component: () => import('@/views/dashboards/designer'),
  },
  {
    path: '/accounts',
    name: 'accounts',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Accounts Manager' ,
      authRequired: true,
    },
    component: () => import('@/views/accounts'),
  },
  {
    path: '/accounts/account',
    name: 'account',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Account Editor' ,
      authRequired: true,
    },
    component: () => import('@/views/accounts/account'),
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Contacts Manager' ,
      authRequired: true,
    },
    component: () => import('@/views/contacts'),
  },
  {
    path: '/contacts/contact',
    name: 'contact',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Contact Editor' ,
      authRequired: true,
    },
    component: () => import('@/views/contacts/contact'),
  },
  {
    path: '/leads',
    name: 'leads',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Leads Manager' ,
      authRequired: true,
    },
    component: () => import('@/views/leads'),
  },
  {
    path: '/leads/lead',
    name: 'lead',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Lead Editor' ,
      authRequired: true,
    },
    component: () => import('@/views/leads/lead'),
  },
  {
    path: '/teams',
    name: 'teams',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Teams' ,
      authRequired: true,
    },
    component: () => import('@/views/teams'),
  },
  {
    path: '/teams/team',
    name: 'team',
    meta: { 
      layout: 'crm', 
      friendlyRouteName: 'crm-layout' ,
      authRequired: true,
    },
    component: () => import('@/views/teams/team'),
  },
  {
    path: '/my/calendar',
    name: 'mycalendar',
    meta: { 
      layout: 'default-app-slim', 
      friendlyRouteName: 'My Calendar',
      authRequired: true,
    },
    component: () => import('@/views/my/calendar'),
  },
  {
    path: '/my/profile',
    name: 'myprofile',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'My Profile',
      authRequired: true,
    },
    component: () => import('@/views/my/profile'),
  },
  {
    path: '/support/systemupdates',
    name: 'systemupdates',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'System Updates',
      authRequired: true,
    },
    component: () => import('@/views/support/systemupdates'),
  },
  {
    path: '/sheets/sheet',
    name: 'sheet',
    meta: { 
      layout: 'default-app-slim', 
      friendlyRouteName: 'Sheet',
      authRequired: true,
    },
    component: () => import('@/views/sheets/sheet'),
  },
  {
    path: '/my/activity',
    name: 'myactivity',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'My Activity',
      authRequired: true,
    },
    component: () => import('@/views/my/activity'),
  },
  {
    path: '/my/settings',
    name: 'mysettings',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'My Settings',
      authRequired: true,
    },
    component: () => import('@/views/my/settings'),
  },
  {
    path: '/my/messages',
    name: 'mymessages',
    meta: { 
      layout: 'default-app-slim', 
      friendlyRouteName: 'My Messages',
      authRequired: true,
    },
    component: () => import('@/views/my/messages'),
  },
  {
    path: '/my/dashboard',
    name: 'mydashboard',
    meta: { 
      layout: 'default-app-slim', 
      friendlyRouteName: 'My Dashboard',
      authRequired: true,
    },
    component: () => import('@/views/my/dashboard'),
  },
  {
    path: '/my/documents',
    name: 'mydocuments',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'My Documents' ,
      authRequired: true,
    },
    component: () => import('@/views/my/documents'),
  },
  {
    path: '/my/learning',
    name: 'mylearning',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'My Learning' ,
      authRequired: true,
    },
    component: () => import('@/views/my/documents'),
  },
  {
    path: '/my/learning/journey',
    name: 'mylearningjourney',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'My Learning Journey',
      authRequired: true, 
    },
    component: () => import('@/views/my/documents'),
  },
  {
    path: '/my/tickets',
    name: 'mytickets',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'My Tickets' ,
      authRequired: true,
    },
    component: () => import('@/views/my/tickets'),
  },
  {
    path: '/desk',
    name: 'desk',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Support Desk' ,
      authRequired: true,
    },
    component: () => import('@/views/desk'),
  },
  {
    path: '/desk/tickets',
    name: 'tickets',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Support Tickets' ,
      authRequired: true,
    },
    component: () => import('@/views/desk/tickets'),
  },
  {
    path: '/desk/recurringTickets',
    name: 'recurringtickets',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Recurring Tickets' ,
      authRequired: true,
    },
    component: () => import('@/views/desk/recurringTickets'),
  },
  {
    path: '/search',
    name: 'search',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Search Results' ,
      authRequired: true,
    },
    props: true,
    component: () => import('@/views/search'),
  },
  {
    path: '/desk/tasks',
    name: 'tasks',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Support Tasks' ,
      authRequired: true,
    },
    component: () => import('@/views/desk/tasks'),
  },
  {
    path: '/assets/manager',
    name: 'assetsmanager',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Asset Manager' ,
      authRequired: true,
    },
    component: () => import('@/views/assets/manager/index'),
  },
  {
    path: '/assets/dashboard',
    name: 'assetsdashboard',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Assets Dashboard' ,
      authRequired: true,
    },
    component: () => import('@/views/assets/dashboard/index'),
  },
  {
    path: '/assets/asset',
    name: 'asset',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Asset' ,
      authRequired: true,
    },
    component: () => import('@/views/assets/asset/index'),
  },
  {
    path: '/people',
    name: 'people',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Dev Tools' ,
      authRequired: true,
    },
    component: () => import('@/views/people'),
  },
  {
    path: '/devteam',
    name: 'devteam',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Dev Tools' ,
      authRequired: true,
    },
    component: () => import('@/views/devteam'),
  },
  {
    path: '/devteam/icons',
    name: 'devteamicons',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Dev Icons' ,
      authRequired: true,
    },
    component: () => import('@/views/devteam/icons'),
  },
  {
    path: '/devteam/dates',
    name: 'devteamdates',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Dev Dates' ,
      authRequired: true,
    },
    component: () => import('@/views/devteam/dates'),
  },
  {
    path: '/crm',
    name: 'crm',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'CRM Dashboard' ,
      authRequired: true,
    },
    component: () => import('@/views/crm'),
  }
  ,
  {
    path: '/crm/usage',
    name: 'usage',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Usage' ,
      authRequired: true,
    },
    component: () => import('@/views/crm/usage'),
  }
  ,
  {
    path: '/billing/invoices',
    name: 'invoices',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Invoices' ,
      authRequired: true,
    },
    component: () => import('@/views/billing/invoices'),
  }
  ,
  {
    path: '/support/knowledgebase',
    name: 'knowledgebase',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Knowledgebase' ,
      authRequired: true,
    },
    component: () => import('@/views/support/knowledgebase'),
  }
  ,
  {
    path: '/people/employees',
    name: 'employees',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'employees' ,
      authRequired: true,
    },
    component: () => import('@/views/people/employees'),
  }
  ,
  {
    path: '/support/contactsupport',
    name: 'contactsupport',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Contact Support' ,
      authRequired: true,
    },
    component: () => import('@/views/support/contactsupport'),
  }
  ,
  {
    path: '/crm/activity',
    name: 'activity',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Activity' ,
      authRequired: true,
    },
    component: () => import('@/views/crm/activity'),
  } 
  ,
  {
    path: '/crm/filehistory',
    name: 'filehistory',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'File History' ,
      authRequired: true,
    },
    component: () => import('@/views/crm/filehistory'),
  } ,
  {
    path: '/crm/assethistory',
    name: 'assethistory',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Asset History' ,
      authRequired: true,
    },
    component: () => import('@/views/crm/assethistory'),
  } 
  ,
  {
    path: '/crm/settings', //
    name: 'settings',
    meta: { 
      layout: 'crm-layout', 
      friendlyRouteName: 'Settings' ,
      authRequired: true,
    },
    component: () => import('@/views/crm/settings'),
  },
  {
    path: '/reports/ticketusersummary', //
    name: 'ticketUserSummary',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Ticket User Summary' ,
      authRequired: true,
    },
    component: () => import('@/views/reports/ticketByUserSummary'),
  },
  {
    path: '/reports/accountIssues', //
    name: 'accountIssues',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Account Issues' ,
      authRequired: true,
    },
    component: () => import('@/views/reports/accountIssues'),
  },
  {
    path: '/reports/slaadherence', //
    name: 'slaadherence',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'SLA Adherence' ,
      authRequired: true,
    },
    component: () => import('@/views/reports/slaadherence'),
  },
  {
    path: '/reports/ticketaccountsummary', //
    name: 'ticketAccountSummary',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Ticket Account Summary' ,
      authRequired: true,
    },
    component: () => import('@/views/reports/ticketByAccountSummary'),
  },
  {
    path: '/reports/ticketteamsummary', //
    name: 'ticketTeamSummary',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Ticket Team Summary' ,
      authRequired: true,
    },
    component: () => import('@/views/reports/ticketByTeamSummary'),
  },
  {
    path: '/reports/ticketissuetypesummary', //
    name: 'ticketIssueTypeSummary',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Ticket Issue Type Summary' ,
      authRequired: true,
    },
    component: () => import('@/views/reports/ticketByIssueTypeSummary'),
  },
  {
    path: '/reports/ticketprioritysummary', //
    name: 'ticketPrioritySummary',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Ticket Priority Summary' ,
      authRequired: true,
    },
    component: () => import('@/views/reports/ticketByPrioritySummary'),
  },
  {
    path: '/reports/ticketcontactsummary', //
    name: 'ticketContactSummary',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Ticket Contact Summary' ,
      authRequired: true,
    },
    component: () => import('@/views/reports/ticketByContactSummary'),
  },
  {
    path: '/projects', //
    name: 'projects',
    meta: { 
      layout: 'default-project-layout', 
      friendlyRouteName: 'projects' ,
      authRequired: true,
    },
    component: () => import('@/views/projects'),
  },
  {
    path: '/projects/project', //
    name: 'project',
    meta: { 
      layout: 'default-project-layout', 
      friendlyRouteName: 'project' ,
      authRequired: true,
    },
    component: () => import('@/views/projects/project'),
  },
  {
    path: '/reports', //
    name: 'reports',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Reports' ,
      authRequired: true,
    },
    component: () => import('@/views/reports'),
  },
  {
    path: '/irrigation', //
    name: 'irrigationreport',
    meta: { 
      layout: 'default-project-layout', 
      friendlyRouteName: 'Irrigation Report' ,
      authRequired: true,
    },
    component: () => import('@/views/bespoke/jepco/irrigation'),
  },
  {
    path: '/people/employees/employee', //
    name: 'employee',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Employee Profile' ,
      authRequired: true,
    },
    component: () => import('@/views/people/employees/employee'),
  },
  {
    path: '/assets/manager/edit', //
    name: 'assetsmanageredit',
    meta: { 
      layout: 'default-app-layout', 
      friendlyRouteName: 'Manage Assets' ,
      authRequired: true,
    },
    component: () => import('@/views/assets/manager/edit'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.afterEach(() => {
  // Turn the loader off. 
  store.commit('updateFSIsLoading', false)
})

 router.beforeEach((to, from, next) => {
  // turn the loader on.  
  store.commit('updateFSIsLoading', true)
   // check for access token if requires auth.  
   if (to.matched.some(record => record.meta.authRequired)) {
     if (!localStorage.trimToken) { // do we have a trim token for access ?
       next({
         path: '/login',
         query: { redirect: to.fullPath },
       })
     } else { // we have a token. Lets validate.
        Vue.axios.get('/system/Check_UserAccess')
        .then((response) => {
          if (response.data.hasAccess === false || response.data.hasAccess === 'false') {
            this.$notification['warning']({
              message: 'Authenticate Error',
              description: 'Unable to Authenticate',
            })
            next({
              path: '/login',
              query: { redirect: to.fullPath },
            })
          } else {
            if (!store.state.userSettings.isAuthed) {
              Vue.axios.get('/users/Get_UserSettings/')
                .then((response) => {
                  store.commit('updateUserSettings', response.data)
                  if (to.path === '/') {
                    next({
                      path: '/dashboards/' + store.state.userSettings.dashboard,
                    })
                  } else {
                    next()
                  }
                })
                .catch((error) => {
                  alert(error)
                })
            } else 
            {
              if (to.path === '/') {
                next({
                  path: '/dashboards/' + store.state.userSettings.dashboard,
                })
              } else {
                next()
              }
            }
          }
        })
        .catch(() => {
          next({
            path: '/login',
            query: { redirect: to.fullPath },
          })
        })
     }
   } else
   {
    next()
   }
 })

 

export default router
