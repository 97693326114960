<template>
  <div style="position:relative;"> 
        <input class="border-transparent form-control form-focus-none" v-model="searchTerm" @input="debounceInput" placeholder="Search Anything!"/>
        <div v-if="searchTerm.length" class="globalSearchResults">
            <div class="globalSearchResultsInner">
                <a class="float-right" @click="searchTerm = ''"><em class="clear icon ni ni-delete"/></a>
                <div v-if="$hasTenantModule(1)">
                    <h6>Tickets</h6>
                    <ul>
                        <li class="result text-center" v-for="result in ticketsArray" :key="result.id">
                            <span @click="viewResult(result)" v-if="result.entityName === 'Ticket'">{{ result.name }}</span>
                        </li>
                        <li class="viewMore text-center" @click="viewMoreResults('Tickets')">View More Results</li>
                    </ul>
                    <hr>  
                </div>
                <h6>Accounts</h6>
                <ul>
                    <li class="result text-center" v-for="result in accountsArray" :key="result.id">
                        <span @click="viewResult(result)" v-if="result.entityName == 'Account'">{{ result.name }}</span>
                    </li>
                    <li class="viewMore text-center" @click="viewMoreResults('Accounts')">View More Results</li>
                </ul>
                <hr>
                <h6>Contacts</h6>
                <ul>
                    <li class="result text-center" v-for="result in contactsArray" :key="result.id">
                        <span @click="viewResult(result)" v-if="result.entityName == 'Contact'">{{ result.name }}</span>
                    </li>
                    <li class="viewMore text-center" @click="viewMoreResults('Contacts')">View More Results</li>
                </ul>
            </div>
        </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default { 
    data () {
        return {
            searchTerm: '',
            searchResults: []
        }
    },
    computed: {
        ticketsArray() {
            return this.searchResults.filter(res => {
                if ( res.entityName === 'Ticket') {
                    return res
                } else {
                    return false
                }
            })
        },
        accountsArray() {
            return this.searchResults.filter(res => {
                if ( res.entityName === 'Account') {
                    return res
                } else {
                    return false
                }
            })
        },
        contactsArray() {
            return this.searchResults.filter(res => {
                if ( res.entityName === 'Contact') {
                    return res
                } else {
                    return false
                }
            })
        }
    },
    methods: {
        debounceInput: _.debounce(function () {
            this.search()
        }, 800),
        search() {
            this.$http.get('/search/Get_GlobalSearchResults/' + this.searchTerm)
            .then((response) => {
                this.searchResults = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        viewResult(result) {
            if (result.entityName == 'Ticket') {
                this.$router.push({name: 'search', query: {entityName: result.entityName}, params: {searchTerm: this.searchTerm, ticketId: result.id}})
            }
            if (result.entityName == 'Account') {
                this.$router.push({name: 'account', query: {accountId: result.id}})
            }
            if (result.entityName == 'Contact') {
                this.$router.push({name: 'contact', query: {contactId: result.id}})
            }
            this.searchTerm = ''
        },
        viewMoreResults(routeName) {
            this.$router.push({name: 'search', query: {entityName: routeName}, params: {searchTerm: this.searchTerm}})
            this.searchTerm = ''
        }
    }
}
</script>

<style>
.clear {
    font-size: 20px;
}
.clear:hover {
    color: red;
}
.viewMore {
    background-color: rgb(129, 206, 216);
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
}
.viewMore:hover {
    background-color: rgb(69, 110, 116);
    color: #fff;
    border-radius: 10px;
}
.result {
    border: 1px solid rgba(118, 132, 146, 0.4);
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 4px;
}
.result:hover {
    border: 1px solid rgba(118, 132, 146, 0.4);
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 4px;
    color: rgb(129, 206, 216);
}
.globalSearchResultsInner{
     
    border-radius: 10px;
    margin:2px;
    padding:10px;
    height:100%;
    overflow: scroll;
}
.globalSearchResults{
    position: absolute;
    height:200px;
    width:400px;
    bottom:-200px;
    left:-200px;
    background-color: #fff;
    padding:10px;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.38); 
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.38);
    z-index: 1;
}
</style>