<template>
  <div class="row">
        <div class="col-12">
            <table class="table" v-if="activeTimers.length">
                <thead>
                    <th>Ticket ID</th>
                    <th>Title</th>
                    <th>Time Spent</th>
                    <th class="text-center">Actions</th>
                </thead>
                <tbody>
                    <tr v-for="a in activeTimers" :key="a.id">
                        <td>TN{{ a.ticketId }}</td>
                        <td>{{a.title}}</td>
                        <td>{{ $moment(a.startTime).format('HH:mm a DD/MM/YYYY')}}</td>
                        <td class="text-center">
                            <a @click="stopTicketTimeAllocation(a)" class="btn-icon btn-danger btn-xs btn btn-round"><em class="icon ni ni-pause-fill" style="color: white;"/></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p v-else class="text-center alert"> You are not currently working on any tickets.</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        activeTimers: {
            type: Array
        }
    },
    data() {
        return {
            timeAllocation: {},
            ticketTimeAllocations: null,
        }
    },
    methods: {
        stopTicketTimeAllocation(ta){
            this.timeAllocation.ticketId = ta.ticketId
            this.timeAllocation.userId = ta.userId
            this.$http.post('/desk/Stop_TicketTimeAllocation', this.timeAllocation)
            .then(() => {
                this.getMyActiveTicketTimeAllocation()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            }) 
        },
    }
}
</script>

<style>

</style>